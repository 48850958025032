import { ArrSummaryPriceDataTableT } from "../../functionsUI/summaryInfo/getArrSummaryProductsForCabinets";
import { getColorValueDataTableFromColorUI } from "../../functionsUI/сompareColorUIAndColorDataTable";
import {
  ArrSummaryFinishing,
  SummaryFinishingInfo,
} from "../../store/selectors/summarySelectors";
import { ArrPriceDataTableT } from "../../store/slices/dataTablesSlice/dataTablesSliceT";
import { ProductsOther } from "../../utils/constants/cabinets";
import { ColorOptionsValuesT } from "../../utils/constants/colorsRoom";
import { generateImageLinkBySha } from "../../utils/other/thumbnail";
import { THREEKIT_IMAGES_URL, THREEKIT_URL } from "../../utils/threekit/threekitConfig/threekitConfig";
import {
  getLengthEdgeScribeMouldingSummary,
  getMouldingLengthCabinetsWall,
  ObjMouldingLengthCabinetsWallT,
  ObjMouldingTopBottomLengthI,
} from "../cabinets/cabinetsWall/getMouldingLength";

const ID_3IN_UPPER_MOULDING = "LWSCLICK-70";
const ID_6IN_UPPER_MOULDING = "1112";
const ID_LOWER_MOULDING = "LWSCLICK-71";
const ID_EDGE_SCRIBE_MOLDING = "LWSCLICK-71";

const getObjProductMoulding = (id: string) => {
  return ProductsOther.find((objProduct) => objProduct["id"] === id);
};

const getCountMouldingFromLength = (length: number): number => {
  return Math.ceil(length / 2.4384);
};

const getSummaryLengthMoulding = (
  objMouldingLengthCabinetsWall: ObjMouldingLengthCabinetsWallT
): ObjMouldingTopBottomLengthI => {
  return Object.values(objMouldingLengthCabinetsWall).reduce(
    (accObj: ObjMouldingTopBottomLengthI, modelMouldingLength) => {
      return {
        lengthMouldingTop:
          accObj["lengthMouldingTop"] +
          modelMouldingLength["lengthMouldingTop"],
        lengthMouldingBottom:
          accObj["lengthMouldingTop"] +
          modelMouldingLength["lengthMouldingTop"],
      };
    },
    {
      lengthMouldingTop: 0,
      lengthMouldingBottom: 0,
    }
  );
};

const getObjSummaryForMoulding = (
  idUpperMoulding: string,
  summaryLengthMoulding: ObjMouldingTopBottomLengthI
): SummaryFinishingInfo | undefined => {
  const productObj = getObjProductMoulding(idUpperMoulding);
  if (!!productObj) {
    return {
      id: idUpperMoulding,
      name: productObj["label"],
      count: getCountMouldingFromLength(
        summaryLengthMoulding["lengthMouldingTop"]
      )
    };
  }
  return undefined;
};

export const getSummaryMoulding = ({
  priceDatatable,
  upperMoulding,
  lowerMoulding,
  edgeScribeMolding,
  colorValueCabinetsWall
}: {
  priceDatatable: ArrPriceDataTableT;
  upperMoulding: string | boolean;
  lowerMoulding: boolean;
  edgeScribeMolding: boolean;
  colorValueCabinetsWall: ColorOptionsValuesT;
}): ArrSummaryPriceDataTableT => {

  const mouldingLengthCabinetsWall = getMouldingLengthCabinetsWall();
  const summaryLengthMoulding = getSummaryLengthMoulding(
    mouldingLengthCabinetsWall
  );
  const convertedColorValueCabinetsWall = getColorValueDataTableFromColorUI(colorValueCabinetsWall);

  const resultArr: ArrSummaryPriceDataTableT = [];

  if (!!upperMoulding) {
    let idUpperMoulding = ID_3IN_UPPER_MOULDING;

    if (upperMoulding === "3in") idUpperMoulding = ID_3IN_UPPER_MOULDING;

    if (upperMoulding === "6in") idUpperMoulding = ID_6IN_UPPER_MOULDING;

    const productInfoFromDataTable = priceDatatable.find(
      (objProduct) =>
        objProduct["ItemNumber"] === idUpperMoulding &&
        objProduct["Color"] === convertedColorValueCabinetsWall
    );

    const objSummaryForMouldingUpper = getObjSummaryForMoulding(
      idUpperMoulding,
      summaryLengthMoulding
    );

    if (!!productInfoFromDataTable && !!objSummaryForMouldingUpper) {
      resultArr.push({
        ...productInfoFromDataTable,
        thumbnail: generateImageLinkBySha(productInfoFromDataTable["thumbnail"]),
        count: objSummaryForMouldingUpper["count"],
      });
    }
  }

  if (lowerMoulding) {
    const productInfoFromDataTable = priceDatatable.find(
      (objProduct) =>
        objProduct["ItemNumber"] === ID_LOWER_MOULDING &&
        objProduct["Color"] === convertedColorValueCabinetsWall
    );
    const objSummaryForMouldingLower = getObjSummaryForMoulding(
      ID_LOWER_MOULDING,
      summaryLengthMoulding
    );
    if (!!productInfoFromDataTable && !!objSummaryForMouldingLower) {
      resultArr.push({
        ...productInfoFromDataTable,
        thumbnail: generateImageLinkBySha(productInfoFromDataTable["thumbnail"]),
        count: objSummaryForMouldingLower["count"],
      });
    }
  }

  if (edgeScribeMolding) {
    const productInfoFromDataTable = priceDatatable.find(
      (objProduct) =>
        objProduct["ItemNumber"] === ID_EDGE_SCRIBE_MOLDING &&
        objProduct["Color"] === convertedColorValueCabinetsWall
    );
    const summaryLengthEdgeScribeMolding = getLengthEdgeScribeMouldingSummary();
    if (!!productInfoFromDataTable) {
      resultArr.push({
        ...productInfoFromDataTable,
        thumbnail: generateImageLinkBySha(productInfoFromDataTable["thumbnail"]),
        count: getCountMouldingFromLength(summaryLengthEdgeScribeMolding),
      });
    }
  }

  return resultArr;
};
