import {
  ArrPriceDataTableT,
  PriceDataTableI,
} from "../../store/slices/dataTablesSlice/dataTablesSliceT";
import { ColorOptionsValuesT } from "../../utils/constants/colorsRoom";
import { generateImageLinkBySha } from "../../utils/other/thumbnail";
// import { FramedOptionsValuesT } from "../../utils/constants/framedOptions";
import { THREEKIT_IMAGES_URL, THREEKIT_URL } from "../../utils/threekit/threekitConfig/threekitConfig";
import { getColorValueDataTableFromColorUI } from "../сompareColorUIAndColorDataTable";
// import { getFrameValueDataTableFromFrameUI } from "../сompareFrameUIAndFrameDataTable";

export interface SummaryPriceDataTableI extends PriceDataTableI {
  count: number;
}
export type ArrSummaryPriceDataTableT = SummaryPriceDataTableI[];
export type ObjSummaryCabinetsT = {
  [key in string]: SummaryPriceDataTableI;
};

export const getArrSummaryProductsForCabinets = (
  arrIdsFromUI: string[],
  priceDatatable: ArrPriceDataTableT,
  // frameValue: FramedOptionsValuesT,
  colorValue: ColorOptionsValuesT
): ArrSummaryPriceDataTableT => {
  // const convertedFrameValue = getFrameValueDataTableFromFrameUI(frameValue);
  const convertedColorValue = getColorValueDataTableFromColorUI(colorValue);

  let objSummaryCabinets: ObjSummaryCabinetsT = {};
  arrIdsFromUI.forEach((id) => {
    if (objSummaryCabinets.hasOwnProperty(id)) {
      objSummaryCabinets[id]["count"] = ++objSummaryCabinets[id]["count"];
      return;
    }

    const productInfoFromDataTable = priceDatatable.find(
      (objProduct) =>
        objProduct["ItemNumber"] === id &&
        // objProduct["Framed"] === convertedFrameValue &&
        objProduct["Color"] === convertedColorValue
    );

    if (!!productInfoFromDataTable) {
      return (objSummaryCabinets[id] = {
        ...productInfoFromDataTable,
        thumbnail: generateImageLinkBySha(productInfoFromDataTable["thumbnail"]),
        count: 1,
      });
    }
  });

  return Object.values(objSummaryCabinets);
};

export const mergeArrSummaryProducts = (
  array: ArrSummaryPriceDataTableT
): ArrSummaryPriceDataTableT => {
  const resultArray: ArrSummaryPriceDataTableT = [];
  const cloneArray = array.slice(0);
  cloneArray.forEach((obj) => {
    const foundObj = resultArray.find(
      (item) =>
        item["ItemNumber"] === obj["ItemNumber"] &&
        // item["Framed"] === obj["Framed"] &&
        item["Color"] === obj["Color"]
    );
    if (foundObj) {
      foundObj.count += obj.count;
    } else {
      resultArray.push(obj);
    }
  });

  return resultArray;
};
