export enum COLORS_VALUES {
  WHITE = "WT",
  GREY = "GR",
  GREIGE = "Greige Shaker",
  WALNUT = "Walnut Shaker",
}

export type ColorOptionsValuesT =
  | COLORS_VALUES.WHITE
  | COLORS_VALUES.GREY
  | COLORS_VALUES.GREIGE
  | COLORS_VALUES.WALNUT;

export interface ValueCabinetColorI {
  id: string;
  value: COLORS_VALUES;
  label: string;
  color: string;
  isDisabled?: boolean;
}

export interface CabinetColorGroupI {
  label: string;
  value: string;
  values: ValueCabinetColorI[];
}

export interface CabinetColorI {
  [key: string]: CabinetColorGroupI;
}

export const ColorValues: ValueCabinetColorI[] = [
  {
    id: "WhiteShaker",
    value: COLORS_VALUES.WHITE,
    label: "Classic White Shaker",
    color: "#EBE7E5",
  },
  // {
  //   id: "GreyShaker",
  //   value: COLORS_VALUES.GREY,
  //   label: "Grey Shaker",
  //   color: '#BBB6AC',
  // },
  {
    id: "GreigeShaker",
    value: COLORS_VALUES.GREIGE,
    label: "Greige Shaker",
    color: "#D1CBC1",
  },
  {
    id: "WalnutShaker",
    value: COLORS_VALUES.WALNUT,
    label: "Walnut Shaker",
    color: "#503F2C",
  },
];

export const CabinetColor: CabinetColorI = {
  ColorAllCabinets: {
    label: "All Cabinets Color",
    value: "",
    values: ColorValues,
  },
  ColorBaseCabinets: {
    label: "Base Cabinets Color",
    value: "",
    values: ColorValues,
  },
  ColorWallCabinets: {
    label: "Wall Cabinets Color",
    value: "",
    values: ColorValues,
  },
  ColorIslandCabinets: {
    label: "Island Cabinets Color",
    value: "",
    values: ColorValues,
  },
};
