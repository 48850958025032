import { IConfiguration, IConfigurationArray } from "@threekit-tools/treble/dist/types";
import { ArrSummaryPriceDataTableT, ObjSummaryCabinetsT } from "../../functionsUI/summaryInfo/getArrSummaryProductsForCabinets";
import { getColorValueDataTableFromColorUI } from "../../functionsUI/сompareColorUIAndColorDataTable";
// import { getFrameValueDataTableFromFrameUI } from "../../functionsUI/сompareFrameUIAndFrameDataTable";
import { ArrPriceDataTableT } from "../../store/slices/dataTablesSlice/dataTablesSliceT";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { ColorOptionsValuesT } from "../../utils/constants/colorsRoom";
// import { FramedOptionsValuesT } from "../../utils/constants/framedOptions";
import { getFullConfigurationAttribute } from "../../utils/threekit/general/getFunctions";
import { ATTRIBUTES_DECORATIVE_PANELS } from "../cabinets/configuration/decorativePanel";
import { THREEKIT_IMAGES_URL, THREEKIT_URL } from "../../utils/threekit/threekitConfig/threekitConfig";
import { generateImageLinkBySha } from "../../utils/other/thumbnail";

const ID_FRIDGE_PANELS_84 = "LWSCLICK-67";
const ID_FRIDGE_PANELS_90 = "LWSCLICK-68";

export const getSummaryFridgePanels = (
  priceDatatable: ArrPriceDataTableT,
  // frameValue: FramedOptionsValuesT,
  colorValue: ColorOptionsValuesT
): ArrSummaryPriceDataTableT => {

  // const convertedFrameValue = getFrameValueDataTableFromFrameUI(frameValue);
  const convertedColorValue = getColorValueDataTableFromColorUI(colorValue);
  const fullConfigurationCabinetsWall = getFullConfigurationAttribute(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL
  ) as IConfigurationArray;

  let objSummaryFridgePanels: ObjSummaryCabinetsT = {};

  fullConfigurationCabinetsWall.forEach((attributeFullValue) => {
    const configuration = attributeFullValue["configuration"] as IConfiguration;
    if (
      !!configuration &&
      !!configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] &&
      configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] === "Yes"
    ) {
      const decorativeLocation = configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION];
      const decorativeSize = configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_FRIDGE_SIZE];

      let idPanel = ID_FRIDGE_PANELS_90;
      let countPanels = 1;

      if (decorativeSize !== undefined && decorativeSize === "90\"") {
        idPanel = ID_FRIDGE_PANELS_90
      } else if (decorativeSize !== undefined && decorativeSize === "84\"") {
        idPanel = ID_FRIDGE_PANELS_84
      }

      if (decorativeLocation === "Left and Right") {
        countPanels = 2;
      }

      if (
        objSummaryFridgePanels.hasOwnProperty(idPanel)
      ) {
        objSummaryFridgePanels[idPanel]["count"] = objSummaryFridgePanels[idPanel]["count"] + countPanels;
        return;
      }

      const productInfoFromDataTablePanelsFridge = priceDatatable.find(
        (objProduct) =>
          objProduct["ItemNumber"] === idPanel &&
          // objProduct["Framed"] === convertedFrameValue &&
          objProduct["Color"] === convertedColorValue
      );

      if (!!productInfoFromDataTablePanelsFridge) {
        objSummaryFridgePanels[idPanel] = {
          ...productInfoFromDataTablePanelsFridge,
          thumbnail: generateImageLinkBySha(productInfoFromDataTablePanelsFridge["thumbnail"]),
          count: countPanels,
        };
      }

    }
  });

  return Object.values(objSummaryFridgePanels);
};