import { IComponent, SECTIONS_KEYS } from "../../types/UI/dynamic-rendering.interfaces";
import {
  groupCabinetsOptions,
  ProductsAppliances,
  ProductsBaseCabinet,
  ProductsWallCabinet,
  PRODUCT_POSITIONS_KEYS,
  PRODUCT_SECTION_GROUP_APPLIANCES,
  PRODUCT_SECTION_GROUP_BASE,
  PRODUCT_SECTION_GROUP_WALL,
} from "./cabinets";
import { COLORS_VALUES, ColorValues } from "./colorsRoom";
// import { FRAMED_OPTIONS_VALUES, FramedOptions } from "./framedOptions";
import { mouldingTopOptions } from "./mouldingOptions";

export type settingConfigT = {
  key: SECTIONS_KEYS;
  name: string;
  setting: IComponent[];
};

export let settingsCabinets: Array<IComponent> = [
  {
    type: "SettingsBlock",
    data: {
      id: "Cabinets",
      typeOptions: "groupingOptions",
      label: "Cabinets",
      embeddedView: [
        {
          type: "Tabs",
          data: {
            id: "PositionCabinet",
            typeOptions: "configurationOptions",
            defaultValue: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
            visibilityState: true,
            embeddedView: [
              {
                type: "OptionsTab",
                data: {
                  id: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
                  typeOptions: "groupingOptions",
                  label: "Base Cabinet",
                  embeddedView: [
                    {
                      type: "FiltersGroup",
                      data: {
                        id: "Cabinets__Tabs__BaseCabinet__Filters",
                        typeOptions: "groupingOptions",
                        embeddedView: [
                          {
                            type: "SelectStandart",
                            data: {
                              id: `FilterGroup_${PRODUCT_POSITIONS_KEYS.BASE_CABINET.replace( /\s/g, "")}_Type`,
                              typeOptions: "configurationOptions",
                              label: "Type",
                              values: [
                                {
                                  label: "All",
                                  value: "All",
                                },
                                {
                                  label: "Doors Only",
                                  value: PRODUCT_SECTION_GROUP_BASE.DOORS_ONLY,
                                },
                                {
                                  label: "Drawers Only",
                                  value: PRODUCT_SECTION_GROUP_BASE.DRAWERS_ONLY,
                                },
                                {
                                  label: "Drawer and Door",
                                  value: PRODUCT_SECTION_GROUP_BASE.DRAWER_AND_DOOR,
                                },
                                {
                                  label: "Corner Units",
                                  value: PRODUCT_SECTION_GROUP_BASE.CORNER_UNITS,
                                },
                                {
                                  label: "Sink Units",
                                  value: PRODUCT_SECTION_GROUP_BASE.SINK_UNITS,
                                },
                              ],
                              defaultValue: "All",
                            },
                          },
                          {
                            type: "SelectStandart",
                            data: {
                              id: `FilterGroup_${PRODUCT_POSITIONS_KEYS.BASE_CABINET.replace( /\s/g, "")}_Width`,
                              typeOptions: "configurationOptions",
                              label: "Width",
                              values: [
                                {
                                  id: "All",
                                  label: "All",
                                  value: "All",
                                },
                                {
                                  id: "12",
                                  label: "12",
                                  value: "12",
                                },
                                {
                                  id: "18",
                                  label: "18",
                                  value: "18",
                                },
                                {
                                  id: "24",
                                  label: "24",
                                  value: "24",
                                },
                                {
                                  id: "30",
                                  label: "30",
                                  value: "30",
                                },
                                {
                                  id: "36",
                                  label: "36",
                                  value: "36",
                                },
                              ],
                              defaultValue: "All",
                            },
                          },
                        ],
                      },
                    },
                    {
                      type: "CategoriesGroup",
                      data: {
                        id: "CategoryCabinet",
                        typeOptions: "configurationOptions",
                        defaultValue: "",
                        embeddedView: [
                          {
                            type: "CategoryGroup",
                            data: {
                              id: PRODUCT_SECTION_GROUP_BASE.DOORS_ONLY,
                              typeOptions: "groupingOptions",
                              label: "Doors Only",
                              values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.BASE_CABINET][PRODUCT_SECTION_GROUP_BASE.DOORS_ONLY],
                            },
                          },
                          {
                            type: "CategoryGroup",
                            data: {
                              id: PRODUCT_SECTION_GROUP_BASE.DRAWERS_ONLY,
                              typeOptions: "groupingOptions",
                              label: "Drawers Only",
                              values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.BASE_CABINET][PRODUCT_SECTION_GROUP_BASE.DRAWERS_ONLY],
                            },
                          },
                          {
                            type: "CategoryGroup",
                            data: {
                              id: PRODUCT_SECTION_GROUP_BASE.DRAWER_AND_DOOR,
                              typeOptions: "groupingOptions",
                              label: "Drawer and Door",
                              values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.BASE_CABINET][PRODUCT_SECTION_GROUP_BASE.DRAWER_AND_DOOR],
                            },
                          },
                          {
                            type: "CategoryGroup",
                            data: {
                              id: PRODUCT_SECTION_GROUP_BASE.CORNER_UNITS,
                              typeOptions: "groupingOptions",
                              label: "Corner Units",
                              values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.BASE_CABINET][PRODUCT_SECTION_GROUP_BASE.CORNER_UNITS],
                            },
                          },
                          {
                            type: "CategoryGroup",
                            data: {
                              id: PRODUCT_SECTION_GROUP_BASE.SINK_UNITS,
                              typeOptions: "groupingOptions",
                              label: "Sink Units",
                              values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.BASE_CABINET][PRODUCT_SECTION_GROUP_BASE.SINK_UNITS],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                type: "OptionsTab",
                data: {
                  id: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
                  typeOptions: "groupingOptions",
                  label: "Wall Cabinet",
                  embeddedView: [
                    {
                      type: "FiltersGroup",
                      data: {
                        id: "Cabinets__Tabs__WallCabinet__Filters",
                        typeOptions: "groupingOptions",
                        embeddedView: [
                          {
                            type: "SelectStandart",
                            data: {
                              id: `FilterGroup_${PRODUCT_POSITIONS_KEYS.WALL_CABINET.replace( /\s/g, "")}_Type`,
                              typeOptions: "configurationOptions",
                              label: "Type",
                              values: [
                                {
                                  label: "All",
                                  value: "All",
                                },
                                {
                                  label: "Doors Only",
                                  value: PRODUCT_SECTION_GROUP_WALL.DOORS_ONLY,
                                },
                                {
                                  label: "Corner Units",
                                  value: PRODUCT_SECTION_GROUP_WALL.CORNER_UNITS,
                                },
                                {
                                  label: "Upper Pantry Unit",
                                  value: PRODUCT_SECTION_GROUP_WALL.UPPER_PANTRY_UNIT,
                                },
                                {
                                  label: "Above the Fridge/Range",
                                  value: PRODUCT_SECTION_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
                                },
                              ],
                              defaultValue: "All",
                            },
                          },
                          {
                            type: "SelectStandart",
                            data: {
                              id: `FilterGroup_${PRODUCT_POSITIONS_KEYS.WALL_CABINET.replace( /\s/g, "")}_Width`,
                              typeOptions: "configurationOptions",
                              label: "Width",
                              values: [
                                {
                                  id: "All",
                                  label: "All",
                                  value: "All",
                                },
                                {
                                  id: "12",
                                  label: "12",
                                  value: "12",
                                },
                                {
                                  id: "18",
                                  label: "18",
                                  value: "18",
                                },
                                {
                                  id: "24",
                                  label: "24",
                                  value: "24",
                                },
                                {
                                  id: "30",
                                  label: "30",
                                  value: "30",
                                },
                                {
                                  id: "36",
                                  label: "36",
                                  value: "36",
                                },
                              ],
                              defaultValue: "All",
                            },
                          },
                        ],
                      },
                    },
                    {
                      type: "CategoriesGroup",
                      data: {
                        id: "CategoryCabinet",
                        typeOptions: "configurationOptions",
                        defaultValue: "",
                        embeddedView: [
                          {
                            type: "CategoryGroup",
                            data: {
                              id: PRODUCT_SECTION_GROUP_WALL.DOORS_ONLY,
                              typeOptions: "groupingOptions",
                              label: "Doors Only",
                              values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.WALL_CABINET][PRODUCT_SECTION_GROUP_WALL.DOORS_ONLY],
                            },
                          },
                          {
                            type: "CategoryGroup",
                            data: {
                              id: PRODUCT_SECTION_GROUP_WALL.CORNER_UNITS,
                              typeOptions: "groupingOptions",
                              label: "Corner Unit",
                              values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.WALL_CABINET][PRODUCT_SECTION_GROUP_WALL.CORNER_UNITS],
                            },
                          },
                          {
                            type: "CategoryGroup",
                            data: {
                              id: PRODUCT_SECTION_GROUP_WALL.UPPER_PANTRY_UNIT,
                              typeOptions: "groupingOptions",
                              label: "Upper Pantry Unit",
                              values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.WALL_CABINET][PRODUCT_SECTION_GROUP_WALL.UPPER_PANTRY_UNIT],
                            },
                          },
                          {
                            type: "CategoryGroup",
                            data: {
                              id: PRODUCT_SECTION_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
                              typeOptions: "groupingOptions",
                              label: "Above the Fridge/Range",
                              values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.WALL_CABINET][PRODUCT_SECTION_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    type: "SettingsProduts",
    data: {
      id: "SettingsProduts",
      typeOptions: "groupingOptions",
      embeddedView: [
        {
          type: "FiltersGroup",
          data: {
            id: "SettingsProduts__Filters",
            typeOptions: "groupingOptions",
            embeddedView: [
              {
                type: "SelectStandart",
                data: {
                  id: "WidthProduts",
                  typeOptions: "configurationOptions",
                  label: "Width",
                  values: [
                    {
                      id: "All",
                      label: "All",
                      value: "All",
                    },
                    {
                      id: "12",
                      label: "12",
                      value: "12",
                    },
                    {
                      id: "18",
                      label: "18",
                      value: "18",
                    },
                    {
                      id: "24",
                      label: "24",
                      value: "24",
                    },
                    {
                      id: "30",
                      label: "30",
                      value: "30",
                    },
                    {
                      id: "36",
                      label: "36",
                      value: "36",
                    },
                  ],
                  defaultValue: "All",
                },
              },
            ],
          },
        },
        {
          type: "OptionsGroup",
          data: {
            id: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
            typeOptions: "configurationOptions",
            dependencySettings: [
              {
                dependencyType: "isVisible",
                idParams: "PositionCabinet",
                isValue: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
              },
            ], 
            defaultValue: [],
            values: ProductsBaseCabinet,
          },
        },
        {
          type: "OptionsGroup",
          data: {
            id: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
            typeOptions: "configurationOptions",
            dependencySettings: [
              {
                dependencyType: "isVisible",
                idParams: "PositionCabinet",
                isValue: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
              },
            ],
            defaultValue: [],
            values: ProductsWallCabinet,
          },
        },
      ],
    },
  },
];

export let settingsIsland: Array<IComponent> = [
  {
    type: "SettingsBlock",
    data: {
      id: "Islands",
      typeOptions: "groupingOptions",
      label: "Island",
      embeddedView: [
        {
          type: "FiltersGroup",
          data: {
            id: "Island__Filters",
            typeOptions: "groupingOptions",
            embeddedView: [
              {
                type: "SelectStandart",
                data: {
                  id: `FilterGroup_${PRODUCT_POSITIONS_KEYS.ISLAND_CABINET.replace( /\s/g, "")}_Type`,
                  typeOptions: "configurationOptions",
                  label: "Type",
                  values: [
                    {
                      label: "All",
                      value: "All",
                    },
                    {
                      label: "Doors Only",
                      value: PRODUCT_SECTION_GROUP_BASE.DOORS_ONLY,
                    },
                    {
                      label: "Drawers Only",
                      value: PRODUCT_SECTION_GROUP_BASE.DRAWERS_ONLY,
                    },
                    {
                      label: "Drawer and Door",
                      value: PRODUCT_SECTION_GROUP_BASE.DRAWER_AND_DOOR,
                    },
                    {
                      label: "Corner Units",
                      value: PRODUCT_SECTION_GROUP_BASE.CORNER_UNITS,
                    },
                    {
                      label: "Sink Units",
                      value: PRODUCT_SECTION_GROUP_BASE.SINK_UNITS,
                    },
                  ],
                  defaultValue: "All",
                },
              },
              {
                type: "SelectStandart",
                data: {
                  id: `FilterGroup_${PRODUCT_POSITIONS_KEYS.ISLAND_CABINET.replace( /\s/g, "")}_Width`,
                  typeOptions: "configurationOptions",
                  label: "Width",
                  values: [
                    // {
                    //   id: "All",
                    //   label: "All",
                    //   value: "All",
                    // },
                    // {
                    //   id: "12",
                    //   label: "12",
                    //   value: "12",
                    // },
                    // {
                    //   id: "18",
                    //   label: "18",
                    //   value: "18",
                    // },
                    // {
                    //   id: "24",
                    //   label: "24",
                    //   value: "24",
                    // },
                    // {
                    //   id: "30",
                    //   label: "30",
                    //   value: "30",
                    // },
                    // {
                    //   id: "36",
                    //   label: "36",
                    //   value: "36",
                    // },
                  ],
                  defaultValue: "All",
                },
              },
            ],
          },
        },
        {
          type: "CategoriesGroup",
          data: {
            id: "CategoryCabinet",
            typeOptions: "configurationOptions",
            defaultValue: "",
            embeddedView: [
              {
                type: "CategoryGroup",
                data: {
                  id: PRODUCT_SECTION_GROUP_BASE.DOORS_ONLY,
                  typeOptions: "groupingOptions",
                  label: "Doors Only",
                  values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.ISLAND_CABINET][PRODUCT_SECTION_GROUP_BASE.DOORS_ONLY],
                },
              },
              {
                type: "CategoryGroup",
                data: {
                  id: PRODUCT_SECTION_GROUP_BASE.DRAWERS_ONLY,
                  typeOptions: "groupingOptions",
                  label: "Drawers Only",
                  values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.ISLAND_CABINET][PRODUCT_SECTION_GROUP_BASE.DRAWERS_ONLY],
                },
              },
              {
                type: "CategoryGroup",
                data: {
                  id: PRODUCT_SECTION_GROUP_BASE.DRAWER_AND_DOOR,
                  typeOptions: "groupingOptions",
                  label: "Drawer and Door",
                  values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.ISLAND_CABINET][PRODUCT_SECTION_GROUP_BASE.DRAWER_AND_DOOR],
                },
              },
              {
                type: "CategoryGroup",
                data: {
                  id: PRODUCT_SECTION_GROUP_BASE.CORNER_UNITS,
                  typeOptions: "groupingOptions",
                  label: "Corner Units",
                  values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.ISLAND_CABINET][PRODUCT_SECTION_GROUP_BASE.CORNER_UNITS],
                },
              },
              {
                type: "CategoryGroup",
                data: {
                  id: PRODUCT_SECTION_GROUP_BASE.SINK_UNITS,
                  typeOptions: "groupingOptions",
                  label: "Sink Units",
                  values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.ISLAND_CABINET][PRODUCT_SECTION_GROUP_BASE.SINK_UNITS],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    type: "SettingsProduts",
    data: {
      id: "SettingsProduts",
      typeOptions: "groupingOptions",
      embeddedView: [
        {
          type: "FiltersGroup",
          data: {
            id: "SettingsProduts__Filters",
            typeOptions: "groupingOptions",
            embeddedView: [
              {
                type: "SelectStandart",
                data: {
                  id: "WidthProduts",
                  typeOptions: "configurationOptions",
                  label: "Width",
                  values: [
                    {
                      id: "All",
                      label: "All",
                      value: "All",
                    },
                    {
                      id: "12",
                      label: "12",
                      value: "12",
                    },
                    {
                      id: "18",
                      label: "18",
                      value: "18",
                    },
                    {
                      id: "24",
                      label: "24",
                      value: "24",
                    },
                    {
                      id: "30",
                      label: "30",
                      value: "30",
                    },
                    {
                      id: "36",
                      label: "36",
                      value: "36",
                    },
                  ],
                  defaultValue: "All",
                },
              },
            ],
          },
        },
        {
          type: "OptionsGroup",
          data: {
            id: PRODUCT_POSITIONS_KEYS.ISLAND_CABINET,
            typeOptions: "configurationOptions",
            defaultValue: [],
            values: ProductsBaseCabinet,
          },
        },
      ],
    },
  },
];

export let settingsAppliances: Array<IComponent> = [
  {
    type: "SettingsBlock",
    data: {
      id: "Appliances",
      typeOptions: "groupingOptions",
      label: "Appliances",
      embeddedView: [
        {
          type: "FiltersGroup",
          data: {
            id: "SettingsProduts__Filters",
            typeOptions: "groupingOptions",
            embeddedView: [
              {
                type: "SelectStandart",
                data: {
                  id: `FilterGroup_${PRODUCT_POSITIONS_KEYS.APPLIANCES.replace( /\s/g, "")}_Type`,
                  typeOptions: "configurationOptions",
                  label: "Type",
                  values: [
                    {
                      label: "All",
                      value: "All",
                    },
                    {
                      label: "Doors Only",
                      value: PRODUCT_SECTION_GROUP_BASE.DOORS_ONLY,
                    },
                    {
                      label: "Drawers Only",
                      value: PRODUCT_SECTION_GROUP_BASE.DRAWERS_ONLY,
                    },
                    {
                      label: "Drawer and Door",
                      value: PRODUCT_SECTION_GROUP_BASE.DRAWER_AND_DOOR,
                    },
                    {
                      label: "Corner Units",
                      value: PRODUCT_SECTION_GROUP_BASE.CORNER_UNITS,
                    },
                    {
                      label: "Sink Units",
                      value: PRODUCT_SECTION_GROUP_BASE.SINK_UNITS,
                    },
                  ],
                  defaultValue: "All",
                },
              },
              {
                type: "SelectStandart",
                data: {
                  id: `FilterGroup_${PRODUCT_POSITIONS_KEYS.APPLIANCES.replace( /\s/g, "")}_Width`,
                  typeOptions: "configurationOptions",
                  label: "Width",
                  values: [
                    {
                      id: "All",
                      label: "All",
                      value: "All",
                    }
                  ],
                  defaultValue: "All",
                },
              },
            ],
          },
        },
        {
          type: "CategoriesGroup",
          data: {
            id: "Cabinets Appliances",
            typeOptions: "configurationOptions",
            defaultValue: "",
            embeddedView: [
              // {
              //   type: "CategoryGroup",
              //   data: {
              //     id: PRODUCT_SECTION_GROUP_APPLIANCES.SINK,
              //     typeOptions: "groupingOptions",
              //     label: "Sink",
              //     values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.APPLIANCES][PRODUCT_SECTION_GROUP_APPLIANCES.SINK],
              //   },
              // },
              {
                type: "CategoryGroup",
                data: {
                  id: PRODUCT_SECTION_GROUP_APPLIANCES.REFRIGERATOR,
                  typeOptions: "groupingOptions",
                  label: "Refrigerator",
                  values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.APPLIANCES][PRODUCT_SECTION_GROUP_APPLIANCES.REFRIGERATOR],
                },
              },
              {
                type: "CategoryGroup",
                data: {
                  id: PRODUCT_SECTION_GROUP_APPLIANCES.DISHWASHER,
                  typeOptions: "groupingOptions",
                  label: "Dishwasher",
                  values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.APPLIANCES][PRODUCT_SECTION_GROUP_APPLIANCES.DISHWASHER],
                },
              },
              {
                type: "CategoryGroup",
                data: {
                  id: PRODUCT_SECTION_GROUP_APPLIANCES.RANGE,
                  typeOptions: "groupingOptions",
                  label: "Range",
                  values: groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.APPLIANCES][PRODUCT_SECTION_GROUP_APPLIANCES.RANGE],
                },
              },
            ],
          },
        },
      ],
    },
  },
];

export let settingsCabinetBuild: Array<IComponent> = [
  {
    type: "SettingsBlock",
    data: {
      id: "CabinetBuild",
      typeOptions: "groupingOptions",
      label: "Cabinet Build Options",
      descriptionText:
        "Build options will apply to all cabinets of the same kind.",
      embeddedView: [
        // {
        //   type: "SwitchStandart",
        //   data: {
        //     id: "CabinetBuildApplyToAll",
        //     typeOptions: "configurationOptions",
        //     label: "Apply to all",
        //     defaultValue: false,
        //     values: [],
        //   },
        // },
        // {
        //   type: "CabinetsBuildGroup",
        //   data: {
        //     id: "CabinetBuild__AllCabinetsGroup",
        //     typeOptions: "groupingOptions",
        //     dependencySettings: [
        //       {
        //         dependencyType: "isVisible",
        //         idParams: "CabinetBuildApplyToAll",
        //         isValue: true,
        //       },
        //     ], 
        //     label: "All Cabinets",
        //     visibilityState: false,
        //     embeddedView: [
        //       // {
        //       //   type: "RadioOptions",
        //       //   data: {
        //       //     id: "AllCabinets_Frame",
        //       //     typeOptions: "configurationOptions",
        //       //     label: "Frame options",
        //       //     defaultValue: "FD",
        //       //     values: FramedOptions,
        //       //   },
        //       // },
        //       {
        //         type: "SwitchSpaceBetween",
        //         data: {
        //           id: "AllCabinets_DecorativeEnd",
        //           typeOptions: "configurationOptions",
        //           label: "Decorative End",
        //           defaultValue: true,
        //           values: [],
        //         },
        //       },
        //     ],
        //   },
        // },







        
        // {
        //   type: "CabinetsBuildGroup",
        //   data: {
        //     id: "CabinetBuild__AllCabinets_Frame",
        //     typeOptions: "groupingOptions",
        //     // dependencySettings: [
        //     //   {
        //     //     dependencyType: "isVisible",
        //     //     idParams: "CabinetBuildApplyToAll",
        //     //     isValue: false,
        //     //   },
        //     // ],
        //     embeddedView: [
        //       {
        //         type: "RadioOptions",
        //         data: {
        //           id: "AllCabinets_Frame",
        //           typeOptions: "configurationOptions",
        //           label: "Frame options",
        //           defaultValue: FRAMED_OPTIONS_VALUES.FRAMED,
        //           values: FramedOptions,
        //         },
        //       },
        //     ],
        //   },
        // },








        {
          type: "CabinetsBuildGroup",
          data: {
            id: "CabinetBuild__DecorativeEnd",
            typeOptions: "groupingOptions",
            label: "Decorative End",
            embeddedView: [
              {
                type: "SwitchSpaceBetween",
                data: {
                  id: "CabinetsBase_DecorativeEnd",
                  typeOptions: "configurationOptions",
                  label: "Base Cabinet",
                  defaultValue: true,
                  values: [],
                },
              },
              {
                type: "SwitchSpaceBetween",
                data: {
                  id: "CabinetsWall_FridgePanels",
                  typeOptions: "configurationOptions",
                  label: "Fridge Panels",
                  defaultValue: true,
                  values: [],
                },
              },
              {
                type: "SwitchSpaceBetween",
                data: {
                  id: "CabinetsIsland_DecorativeEnd",
                  typeOptions: "configurationOptions",
                  label: "Island Panels",
                  defaultValue: true,
                  values: [],
                },
              },
            ],
          },
        },







        // {
        //   type: "CabinetsBuildGroup",
        //   data: {
        //     id: "CabinetBuild__BaseCabinetsGroup",
        //     typeOptions: "groupingOptions",
        //     // dependencySettings: [
        //     //   {
        //     //     dependencyType: "isVisible",
        //     //     idParams: "CabinetBuildApplyToAll",
        //     //     isValue: false,
        //     //   },
        //     // ],
        //     label: "Base Cabinets",
        //     embeddedView: [
        //       // {
        //       //   type: "RadioOptions",
        //       //   data: {
        //       //     id: "BaseCabinets_Frame",
        //       //     typeOptions: "configurationOptions",
        //       //     label: "Frame options",
        //       //     defaultValue: "FD",
        //       //     values: FramedOptions,
        //       //   },
        //       // },
        //       {
        //         type: "SwitchSpaceBetween",
        //         data: {
        //           id: "CabinetsBase_DecorativeEnd",
        //           typeOptions: "configurationOptions",
        //           label: "Decorative End",
        //           defaultValue: true,
        //           values: [],
        //         },
        //       },
        //     ],
        //   },
        // },
        {
          type: "CabinetsBuildGroup",
          data: {
            id: "CabinetBuild__WallCabinetsGroup",
            typeOptions: "groupingOptions",
            // dependencySettings: [
            //   {
            //     dependencyType: "isVisible",
            //     idParams: "CabinetBuildApplyToAll",
            //     isValue: false,
            //   },
            // ],
            label: "Wall Cabinets",
            embeddedView: [
              // {
              //   type: "RadioOptions",
              //   data: {
              //     id: "WallCabinets_Frame",
              //     typeOptions: "configurationOptions",
              //     label: "Frame options",
              //     defaultValue: "FD",
              //     values: FramedOptions,
              //   },
              // },
              {
                type: "SwitchSpaceBetween",
                data: {
                  id: "CabinetsWall_UpperMolding",
                  typeOptions: "configurationOptions",
                  label: "Crown Moulding",
                  defaultValue: false,
                  values: [],
                },
              },
              {
                type: "RadioOptionsIconText",
                data: {
                  id: "CabinetsWall_UpperMoldingSize",
                  typeOptions: "configurationOptions",
                  dependencySettings: [
                    {
                      dependencyType: "isVisible",
                      idParams: "CabinetsWall_UpperMolding",
                      isValue: true,
                    },
                  ],
                  defaultValue: "3in",
                  values: mouldingTopOptions,
                },
              },
              // {
              //   type: "SwitchSpaceBetween",
              //   data: {
              //     id: "CabinetsWall_LowerMolding",
              //     typeOptions: "configurationOptions",
              //     label: "Under Cabinet Moulding",
              //     defaultValue: false,
              //     values: [],
              //   },
              // },
              {
                type: "SwitchSpaceBetween",
                data: {
                  id: "CabinetsWall_EdgeScribeMoulding",
                  typeOptions: "configurationOptions",
                  label: "Edge Scribe Moulding",
                  defaultValue: false,
                  values: [],
                },
              },
            ],
          },
        },
        // {
        //   type: "CabinetsBuildGroup",
        //   data: {
        //     id: "CabinetBuild__IslandCabinetsGroup",
        //     typeOptions: "groupingOptions",
        //     // dependencySettings: [
        //     //   {
        //     //     dependencyType: "isVisible",
        //     //     idParams: "CabinetBuildApplyToAll",
        //     //     isValue: false,
        //     //   },
        //     // ],
        //     label: "Island Cabinets",
        //     embeddedView: [
        //       // {
        //       //   type: "RadioOptions",
        //       //   data: {
        //       //     id: "IslandCabinets_Frame",
        //       //     typeOptions: "configurationOptions",
        //       //     label: "Frame options",
        //       //     defaultValue: "FD",
        //       //     values: FramedOptions,
        //       //   },
        //       // },
        //       {
        //         type: "SwitchSpaceBetween",
        //         data: {
        //           id: "CabinetsIsland_DecorativeEnd",
        //           typeOptions: "configurationOptions",
        //           label: "Decorative End",
        //           defaultValue: true,
        //           values: [],
        //         },
        //       },
        //     ],
        //   },
        // },
      ],
    },
  },
];

export let settingsCabinetColor: Array<IComponent> = [
  {
    type: "SettingsBlock",
    data: {
      id: "CabinetColorOption",
      typeOptions: "groupingOptions",
      label: "Cabinet Color Options",
      descriptionText:
        "Build options will apply to all cabinets of the same kind.",
      embeddedView: [
        {
          type: "SwitchStandart",
          data: {
            id: "ColorApplyToAll",
            typeOptions: "configurationOptions",
            label: "Apply to all",
            defaultValue: false,
            values: [],
          },
        },
        {
          type: "SelectColor",
          data: {
            id: "AllCabinets_Color",
            typeOptions: "configurationOptions",
            dependencySettings: [
              {
                dependencyType: "isVisible",
                idParams: "ColorApplyToAll",
                isValue: true,
              },
            ],
            label: "All Cabinets Color",
            defaultValue: COLORS_VALUES.WHITE,
            values: ColorValues,
            // disabled: true,
          },
        },
        {
          type: "SelectColor",
          data: {
            id: "BaseCabinets_Color",
            typeOptions: "configurationOptions",
            dependencySettings: [
              {
                dependencyType: "isVisible",
                idParams: "ColorApplyToAll",
                isValue: false,
              },
            ],
            label: "Base Cabinets Color",
            defaultValue: COLORS_VALUES.WHITE,
            values: ColorValues,
            // disabled: true,
          },
        },
        {
          type: "SelectColor",
          data: {
            id: "WallCabinets_Color",
            typeOptions: "configurationOptions",
            dependencySettings: [
              {
                dependencyType: "isVisible",
                idParams: "ColorApplyToAll",
                isValue: false,
              },
            ],
            label: "Wall Cabinets Color",
            defaultValue: COLORS_VALUES.WHITE,
            values: ColorValues,
            // disabled: true,
          },
        },
        {
          type: "SelectColor",
          data: {
            id: "IslandCabinets_Color",
            typeOptions: "configurationOptions",
            dependencySettings: [
              {
                dependencyType: "isVisible",
                idParams: "ColorApplyToAll",
                isValue: false,
              },
            ],
            label: "Island Cabinets Color",
            defaultValue: COLORS_VALUES.WHITE,
            values: ColorValues,
            // disabled: true,
          },
        },
      ],
    },
  },
];

export let settingCabinetsBuilderUI: settingConfigT[] = [
  {
    key: SECTIONS_KEYS.CABINETS,
    name: "Cabinets",
    setting: settingsCabinets,
  },
  {
    key: SECTIONS_KEYS.ISLAND,
    name: "Island",
    setting: settingsIsland,
  },

  {
    key: SECTIONS_KEYS.APPLIANCES,
    name: "Appliances",
    setting: settingsAppliances,
  },
  {
    key: SECTIONS_KEYS.CABINET_BUILD,
    name: "Cabinet Build",
    setting: settingsCabinetBuild,
  },
  {
    key: SECTIONS_KEYS.CABINET_COLOR,
    name: "Cabinet Color",
    setting: settingsCabinetColor,
  },
];
