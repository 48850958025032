import { ModelCabinetWallT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { isOTRCabinetWall } from "../checkModels";
import { ConfigurationNewModelT } from "../getObjActiveAndNewValuesAttributeThreekit";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";
import { setPhysicsLockTY } from "./checkLockTranslationCabinetsWall";

export const setInitialVerticalMovement = (newValuesThreekit: ConfigurationNewModelT[]) => {
  // Null Names for all models (old and newly added)
  const completedNullNamesCabinetsWall = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);

  // Form an array of new Wall cabinets that will be placed in the room
  const newListNullNamesCabinetsWall = completedNullNamesCabinetsWall.slice(newValuesThreekit.length * -1);

  // Allow vertical movement by default for “Above the Fridge/Range” cabinets
  newListNullNamesCabinetsWall.forEach((nullName) => {
    if (isOTRCabinetWall(nullName)) {
      setPhysicsLockTY(nullName as ModelCabinetWallT, false);
    }
  });
};
