import {
  IConfiguration,
  IConfigurationArray,
  ISceneResult,
} from "@threekit-tools/treble/dist/types";
import {
  ArrSummaryFinishing,
  ObjSummaryFinishing,
  SummaryFinishingInfo,
} from "../../store/selectors/summarySelectors";
import {
  getFullConfigurationAttribute,
  getNodeFromId,
} from "../../utils/threekit/general/getFunctions";
import { ATTRIBUTES_NAMES_THREEKIT } from "./../../utils/constants/attributesThreekit";
import { ProductsOther } from "./../../utils/constants/cabinets";
import { ArrPriceDataTableT } from "../../store/slices/dataTablesSlice/dataTablesSliceT";
// import { FramedOptionsValuesT } from "../../utils/constants/framedOptions";
import { ColorOptionsValuesT } from "../../utils/constants/colorsRoom";
// import { getFrameValueDataTableFromFrameUI } from "../../functionsUI/сompareFrameUIAndFrameDataTable";
import { getColorValueDataTableFromColorUI } from "../../functionsUI/сompareColorUIAndColorDataTable";
import {
  ArrSummaryPriceDataTableT,
  ObjSummaryCabinetsT,
} from "../../functionsUI/summaryInfo/getArrSummaryProductsForCabinets";
import { ATTRIBUTES_DECORATIVE_PANELS } from "../cabinets/configuration/decorativePanel";
import { THREEKIT_IMAGES_URL, THREEKIT_URL } from "../../utils/threekit/threekitConfig/threekitConfig";
import { metersToInches } from "../../utils/other/measurements";
import { generateImageLinkBySha } from "../../utils/other/thumbnail";

const ID_DECORATIVE_PANEL_CABINETS_BASE_OR_CABINETS_ISLAND_OR_DISHWASHER = "LWSCLICK-69";
// const ID_ISLAND_BACK__DECO_SIDE_PANEL_12 = "1101";
// const ID_ISLAND_BACK__DECO_SIDE_PANEL_18 = "1102";
// const ID_ISLAND_BACK__DECO_SIDE_PANEL_24 = "LWSCLICK-69";

export const getProductDecorativePanelCabinetsBase = () => {
  return ProductsOther.find(
    (objProduct) =>
      objProduct["id"] ===
      ID_DECORATIVE_PANEL_CABINETS_BASE_OR_CABINETS_ISLAND_OR_DISHWASHER
  );
};

const getWidthFromMetadataItem = (item: ISceneResult): number => {
  const metadata = item.configurator.metadata;
  // @ts-ignore
  const metadataWidth = metadata.find((metadataObj) => metadataObj.name === "width");
  if (metadataWidth !== undefined) {
    return Number(metadataWidth.defaultValue);
  };
  return 0;
}

const getCountDecorativeFromLength = (length: number): number => {
  const lengthInch = metersToInches(length);
  return Math.ceil(lengthInch / 24);
}

export const getSummaryDecorativePanelCabinetsBase = (
  priceDatatable: ArrPriceDataTableT,
  // frameValue: FramedOptionsValuesT,
  colorValue: ColorOptionsValuesT
): ArrSummaryPriceDataTableT => {

  // const convertedFrameValue = getFrameValueDataTableFromFrameUI(frameValue);
  const convertedColorValue = getColorValueDataTableFromColorUI(colorValue);
  const fullConfigurationCabinetsBase = getFullConfigurationAttribute(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE
  ) as IConfigurationArray;
  const fullConfigurationAppliances = getFullConfigurationAttribute(
    ATTRIBUTES_NAMES_THREEKIT.APPLIANCES
  ) as IConfigurationArray;

  let objSummaryDecorativePanelCabinetsBase: ObjSummaryCabinetsT = {};
  [...fullConfigurationCabinetsBase, ...fullConfigurationAppliances].forEach((attributeFullValue) => {
    const configuration = attributeFullValue["configuration"] as IConfiguration;
    if (
      !!configuration &&
      !!configuration["Decorative"] &&
      configuration["Decorative"] === "Yes"
    ) {
      const decorativeLocation = configuration["Decorative Location"];
      const id = ID_DECORATIVE_PANEL_CABINETS_BASE_OR_CABINETS_ISLAND_OR_DISHWASHER;

      let count = 1;
      if (decorativeLocation === "Left and Right") count = 2;

      if (objSummaryDecorativePanelCabinetsBase.hasOwnProperty(id)) {
        objSummaryDecorativePanelCabinetsBase[id]["count"] =
          objSummaryDecorativePanelCabinetsBase[id]["count"] + count;
        return;
      }

      const productInfoFromDataTable = priceDatatable.find(
        (objProduct) =>
          objProduct["ItemNumber"] === id &&
          // objProduct["Framed"] === convertedFrameValue &&
          objProduct["Color"] === convertedColorValue
      );

      if (!!productInfoFromDataTable) {
        return (objSummaryDecorativePanelCabinetsBase[id] = {
          ...productInfoFromDataTable,
          thumbnail: generateImageLinkBySha(productInfoFromDataTable["thumbnail"]),
          count: count,
        });
      }

    }
  });

  return Object.values(objSummaryDecorativePanelCabinetsBase);
};

export const getSummaryDecorativePanelCabinetsIsland = (
  priceDatatable: ArrPriceDataTableT,
  // frameValue: FramedOptionsValuesT,
  colorValue: ColorOptionsValuesT
): ArrSummaryPriceDataTableT => {

  // const convertedFrameValue = getFrameValueDataTableFromFrameUI(frameValue);
  const convertedColorValue = getColorValueDataTableFromColorUI(colorValue);
  const fullConfigurationCabinetsIsland = getFullConfigurationAttribute(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND
  ) as IConfigurationArray;

  let objSummaryDecorativePanel: ObjSummaryCabinetsT = {};
  let backPanelLength = 0;
  fullConfigurationCabinetsIsland.forEach((attributeFullValue, index) => {

    const id = ID_DECORATIVE_PANEL_CABINETS_BASE_OR_CABINETS_ISLAND_OR_DISHWASHER;
    const assetId = attributeFullValue["assetId"];
    const configuration = attributeFullValue["configuration"] as IConfiguration;
    const decorativeIslandBack = configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND];

    if (
      !!configuration &&
      !!configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] &&
      configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] === "Yes"
    ) {
      const decorativeLocation = configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION];
      // const label = productDecorativePanelCabinetsBase["label"];
      let countSidePanels = 1;
      if (decorativeLocation === "Left and Right") countSidePanels = 2;

      if (objSummaryDecorativePanel.hasOwnProperty(id)) {

        objSummaryDecorativePanel[id]["count"] = objSummaryDecorativePanel[id]["count"] + countSidePanels;

      } else {

        const productInfoFromDataTable = priceDatatable.find(
          (objProduct) =>
            objProduct["ItemNumber"] === id &&
            // objProduct["Framed"] === convertedFrameValue &&
            objProduct["Color"] === convertedColorValue
        );

        if (!!productInfoFromDataTable) {
          objSummaryDecorativePanel[id] = {
            ...productInfoFromDataTable,
            thumbnail: generateImageLinkBySha(productInfoFromDataTable["thumbnail"]),
            count: countSidePanels,
          };
        }
        
      }

      // const itemNаme = getNodeFromId(assetId)["name"];

      // let idBackDecorativePanel = "";
      // if (!!itemNаme) {
      //   if (itemNаme.includes("12in")) {
      //     idBackDecorativePanel = ID_DECORATIVE_PANEL_CABINETS_BASE_OR_CABINETS_ISLAND_OR_DISHWASHER;
      //   } else if (itemNаme.includes("18in")) {
      //     idBackDecorativePanel = ID_DECORATIVE_PANEL_CABINETS_BASE_OR_CABINETS_ISLAND_OR_DISHWASHER;
      //   } else if (itemNаme.includes("24in")) {
      //     idBackDecorativePanel = ID_DECORATIVE_PANEL_CABINETS_BASE_OR_CABINETS_ISLAND_OR_DISHWASHER;
      //   }
      // }

      // if (idBackDecorativePanel.length > 0) {
      //   if (objSummaryDecorativePanel.hasOwnProperty(idBackDecorativePanel)) {
      //     objSummaryDecorativePanel[idBackDecorativePanel]["count"] =
      //       ++objSummaryDecorativePanel[idBackDecorativePanel]["count"];
      //   } else {
      //     const productInfoFromDataTable = priceDatatable.find(
      //       (objProduct) =>
      //         objProduct["ItemNumber"] === idBackDecorativePanel &&
      //         // objProduct["Framed"] === convertedFrameValue &&
      //         objProduct["Color"] === convertedColorValue
      //     );

      //     if (!!productInfoFromDataTable) {
      //       objSummaryDecorativePanel[idBackDecorativePanel] = {
      //         ...productInfoFromDataTable,
      //         thumbnail: `${THREEKIT_IMAGES_URL}${productInfoFromDataTable["thumbnail"]}`,
      //         count: 1,
      //       };
      //     }
      //   }
      // }

    }

    // Обчислюємо загальну довжину задніх панелей для островних шкафів, для яких ввімкнена задня декоративна панель
    if (
      !!configuration &&
      !!decorativeIslandBack &&
      decorativeIslandBack === "Yes"
    ) {
      const item = getNodeFromId(assetId);
      const widthIslandCabinet = getWidthFromMetadataItem(item);
      backPanelLength += widthIslandCabinet;
    }

    // Додаємо кількість декоративних панелей обраховану з загальної довжини
    // id in objSummaryDecorativePanel - for corner cabinets without decorative panels
    if (index === fullConfigurationCabinetsIsland.length - 1 && id in objSummaryDecorativePanel) {
      objSummaryDecorativePanel[id]["count"] = objSummaryDecorativePanel[id]["count"] + getCountDecorativeFromLength(backPanelLength);
    }

  });

  return Object.values(objSummaryDecorativePanel);
};

export const mergeSummaryArrays = (
  array: ArrSummaryFinishing
): ArrSummaryFinishing => {
  const resultArray: ArrSummaryFinishing = [];
  const cloneArray = array.slice(0);
  cloneArray.forEach((obj) => {
    const foundObj = resultArray.find((item) => item.id === obj.id);
    if (foundObj) {
      foundObj.count += obj.count;
    } else {
      resultArray.push(obj);
    }
  });

  return resultArray;
};
