import React, { useEffect, useRef, useState } from "react";
import s from "./FormModalPrice.module.scss";
import { ButtonMain } from "../../../StandartComponents/Buttons/ButtonMain";
import { getShortId } from "../../../../functionsConfigurator/saveConfiguration/getShortId";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useStoreHooks";
import { getActiveSettingUI, getCollectionsNames } from "../../../../store/selectors/settingsUISelectors";
import { getPositionAndRotationAllCabinets, getSummaryInfoForPDF, getTotalPrice } from "../../../../store/selectors/summarySelectors";
import { getModalInfo } from "../../../../store/selectors/modalsSelectors";
import { MODAL_KEYS } from "../../../../store/slices/modalsSlice/modalsSliceT";
import { generatePDF } from "../../../../api/apiThreekit/generatePDF";
import { LoadBtn } from "../../../../assets/images/svg/LoaderBtn";
import { sendEmailGetRequest } from "../../../../api/sendEmail";
import { setModalVisible } from "../../../../store/slices/modalsSlice/modalsSlice";
import { setCabinetControls } from "../../../../store/slices/settingsUI/settingsUISlice";

export const FormModalPrice = ({ ...props }) => {
  const { drawingId, snapshotsObj, onChangeProjectName } = props;

  const copyedTextInput = useRef<HTMLInputElement>(null);
  const [projectName, setProjectName] = useState<string>('');
  const [isErrorInputProjectName, setIsErrorInputProjectName] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [btnSendMailLoad, setBtnSendMailLoad] = useState<boolean>(false);
  const [btnCopyLinkLoad, setBtnCopyLinkLoad] = useState<boolean>(true);
  const [btnDownloadPDFLoad, setBtnDownloadPDFLoad] = useState<boolean>(false);
  const [linkSavedConfiguration, setLinkSavedConfiguration] =
    useState<string>("");
  const [isShowCopyResponce, setIsShowCopyResponce] = useState<boolean>(false);
  const dispatch = useAppDispatch();


  const objActiveParamsUI = useAppSelector(getActiveSettingUI);
  const transformAllCabinets = useAppSelector(
    getPositionAndRotationAllCabinets
  );
  const modalInfo = useAppSelector((state) =>
    getModalInfo(state)(MODAL_KEYS.PRICE)
  );
  const summaryInfo = useAppSelector(getSummaryInfoForPDF);
  console.log("summaryInfo --- ==== ", summaryInfo);
  const totalPrice = useAppSelector(getTotalPrice);
  const arrCollectionNames = useAppSelector(getCollectionsNames);
  // const valueFrameOption = useAppSelector(getValueFrameOption);

  const saveConfiguration = async () => {
    getShortId({
      objActiveParamsUI,
      transformAllCabinets,
    }).then((shortId: any) => {
      var url = window.location.origin + window.location.pathname;
      const linkText = `${url}?shortId=${shortId[0]}`;
      setLinkSavedConfiguration(linkText);
      setBtnCopyLinkLoad(false);
    });
  };

  useEffect(() => {
    if (modalInfo["isVisible"]) {
      saveConfiguration();
    }
  }, [modalInfo["isVisible"]]);

  const copyLinkToClipboard = () => {
    var copyTextInput = copyedTextInput.current;

    if (copyTextInput !== null) {
      copyTextInput.select();
      copyTextInput.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyTextInput.value);
      setIsShowCopyResponce(true);
    }

    setTimeout(() => {
      setIsShowCopyResponce(false);
    }, 3000);
  };

  const handleClickDownloadPDF = async () => {

    if (projectName.length < 1) {
      return setIsErrorInputProjectName(true);
    }

    setBtnDownloadPDFLoad(true);

    // let id = idNewConfiguration;
    // if (id.length < 1) {
    //   const idGeneratedProject = await getIdGeneratedProject();
    //   if (!!idGeneratedProject && !!idGeneratedProject["idPdf"]) {
    //     id = idGeneratedProject["idPdf"];
    //   }
    // }

    if (drawingId.length > 0) {
      // setIdNewConfiguration(id);
       //@ts-ignore
      window.threekit.player.selectionSet.clear()
      dispatch(setCabinetControls({
        visible: false,
        selectedModelName: '',
      }));
      generatePDF({
        summaryInfo,
        count: summaryInfo.reduce((total, cabinetObj) => total += cabinetObj.count, 0),
        price: !isNaN(totalPrice) ? totalPrice : "TBD",
        emptyImage: "https://www.linkpicture.com/q/not_image_1.png",
        // collectionTitle: getCollectionTitleForPDF(/*valueFrameOption*/),
        collectionTitle: arrCollectionNames,
        name: projectName,
        id: drawingId,
        snapshots: snapshotsObj,
      }).then((res) => {
        setBtnDownloadPDFLoad(false)
      })

    }

  }

  const handleChangeInputProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setIsErrorInputProjectName(false);
    }
    setProjectName(event.target.value);
    onChangeProjectName(event);
  }

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    if (validateEmail(newEmail)) {
      // setEmail(newfEmail);
      setErrorEmail('');
    } else {
      // setEmail('');
      setErrorEmail('Please enter a valid email address');
    }
    setEmail(newEmail);
  }

  const handleClickSendEmail = async () => {

    if (projectName.length < 1) {
      setIsErrorInputProjectName(true);
    }

    if (!validateEmail(email)) {
      setErrorEmail('Please enter a valid email address');
    }

    if (projectName.length < 1 || !validateEmail(email)) return;

    setBtnSendMailLoad(true);

    // let id = idNewConfiguration;
    // if (id.length < 1) {
    //   const idGeneratedProject = await getIdGeneratedProject();
    //   if (!!idGeneratedProject && !!idGeneratedProject["idPdf"]) {
    //     id = idGeneratedProject["idPdf"];
    //   }
    // }

    const pdfBase64 = await generatePDF(
      {
        summaryInfo,
        count: summaryInfo.length,
        price: totalPrice,
        emptyImage: "https://www.linkpicture.com/q/not_image_1.png",
        collectionTitle: arrCollectionNames,
        name: projectName,
        id: drawingId,
        snapshots: snapshotsObj,
      },
      true
    );

    if (drawingId.length > 0 && !!pdfBase64 && linkSavedConfiguration.length > 0) {
      // setIdNewConfiguration(id);

      const objParams = {
        pdf: pdfBase64,
        link: linkSavedConfiguration,
        name: projectName,
        id: drawingId,
        mail: email,
        collection: arrCollectionNames.join(", "),
      };

      sendEmailGetRequest(objParams).then((res) => {

        if (!!res && !!res["status"] && res["status"] === "OK") {
          dispatch(setModalVisible({modalId: MODAL_KEYS.EMAIL_SUCCESS, value: true}))
        } else {
          dispatch(setModalVisible({modalId: MODAL_KEYS.EMAIL_ERROR, value: true}))
        }
        
        setBtnSendMailLoad(false);
      });

    }

  }

  return (
    <>
      <div className={s.nameProjectInputWrap}>
        <input
            type="text"
            value={projectName}
            onChange={(e) => handleChangeInputProjectName(e)}
            className={`${s.inputName} ${isErrorInputProjectName ? s.error : ''}`}
            placeholder="Type project name"
          />
      </div>
      <div className={s.emailQuoteWrap}>
        <input
          type="text"
          value={email}
          className={`${s.inputEmail} ${errorEmail.length > 0 ? s.error : ''}`}
          placeholder="Type in your email"
          onChange={handleEmailChange}
        />
        <div className={s.emailQuoteBtnWrap}>
          <ButtonMain
            text="Email Quote"
            onClick={() => handleClickSendEmail()}
            color="white"
            size="middle"
            bold={true}
            disabled={btnSendMailLoad}
          />
          {btnSendMailLoad && <span className={s.loading}><LoadBtn /></span>}
        </div>
      </div>
      <div className={s.btnsWrap}>
        <div className={s.btnsTwo}>
          <div className={s.copiedLinkWrap}>
            <input
              ref={copyedTextInput}
              type="text"
              value={linkSavedConfiguration}
              hidden={true}
              readOnly={true}
              className={s.linkText}
            />
            <ButtonMain
              text="Copy Link"
              onClick={() => copyLinkToClipboard()}
              color="white"
              size="middle"
              bold={true}
              fullWidth={true}
              disabled={btnCopyLinkLoad}
            />
            {isShowCopyResponce && (
              <p className={s.copiedResponce}>
                The link is copied to the clipboard
              </p>
            )}
          </div>

          <div className={s.btnLoadingWrap}>
            <ButtonMain
              text="Download Quote"
              onClick={handleClickDownloadPDF}
              color="blueDark"
              size="middle"
              bold={true}
              fullWidth={true}
              disabled={btnDownloadPDFLoad}
            />
            {btnDownloadPDFLoad && <span className={s.loading}><LoadBtn /></span>}
          </div>

        </div>
      </div>
    </>
  );
};
